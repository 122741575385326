import React from 'react';

const LogoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='154' height='38' viewBox='0 0 154 38' fill='none'>
      <path
        d='M141.34 16.43C141.34 18.61 139.57 20.38 137.39 20.38C135.21 20.38 133.44 18.61 133.44 16.43C133.44 14.25 135.21 12.48 137.39 12.48C139.57 12.47 141.34 14.24 141.34 16.43Z'
        fill='#FF336D'
      />
      <path
        d='M20.53 15.42C22.74 14.18 24.6 12.23 24.6 8.74C24.6 6.52999 23.89 4.79999 22.48 3.39C20.71 1.62 17.92 0.649995 14.38 0.649995H6.83V0.619995L6.82 0.639995H6.63L5.25 0.649995C3 0.649995 0 2.6 0 6.9C0 7.02 0 9.69 0 9.81V18.59C0 21.15 0 23.06 0 23.06L0.02 26.19C0.02 29.63 2.54 31.58 4.52 31.58L6.58 31.61H14.73C21.72 31.61 26.32 28.78 26.32 23.11C26.33 18.87 24.12 16.79 20.53 15.42ZM8.87 6.61C10.5 6.61 13.37 6.61 13.37 6.61C16.25 6.61 17.84 7.76 17.84 9.8C17.84 12.19 15.94 13.16 12.93 13.16H6.86V8.81999C6.86 8.81999 6.74 6.61 8.87 6.61ZM14.74 25.64C14.74 25.64 10.98 25.64 8.8 25.64C7.16 25.64 6.88 24.33 6.84 23.69V23.34V18.83H14.52C18.02 18.83 19.57 20.11 19.57 22.19C19.56 24.58 17.75 25.64 14.74 25.64Z'
        fill='white'
      />
      <path
        d='M36.3701 0.650015C34.1101 0.650015 31.1201 2.61001 31.1201 6.90001C31.1201 7.02001 31.1201 9.69001 31.1201 9.81001V18.59C31.1201 21.15 31.1201 23.06 31.1201 23.06L31.1401 26.19C31.1401 29.63 33.6601 31.58 35.6401 31.58L37.7001 31.61H37.9401V0.640015H37.7501L36.3701 0.650015Z'
        fill='white'
      />
      <path
        d='M76.7402 6.84C79.1302 6.84 82.9902 6.84 86.3902 6.84C91.8002 6.84 92.5202 0.639995 92.5202 0.639995H76.7402H76.3602H75.0102V0.619995L75.0002 0.639995H74.8102L73.4302 0.649995C71.1702 0.649995 68.1802 2.61 68.1802 6.9C68.1802 7.02 68.1802 9.69 68.1802 9.81V18.59C68.1802 21.15 68.1802 23.06 68.1802 23.06L68.2002 26.19C68.2002 29.63 70.7202 31.58 72.7002 31.58L74.7602 31.61H75.0102C75.0102 31.61 75.6602 31.61 76.7402 31.61C78.7202 31.61 82.1502 31.61 85.6502 31.61C91.0602 31.61 91.7802 25.41 91.7802 25.41H76.7402C75.3002 25.29 75.0402 24.08 75.0102 23.47V23.12V19.22C77.1602 19.22 79.7402 19.22 82.0902 19.22C87.5002 19.22 88.2202 13.02 88.2202 13.02H75.0402V9.02C75.0402 9.02 74.9402 7.04 76.7402 6.84Z'
        fill='white'
      />
      <path
        d='M51.4901 23.48V8.82001V0.640015H51.2701L49.8901 0.650015C47.6301 0.650015 44.6401 2.61001 44.6401 6.90001C44.6401 7.02001 44.6401 9.69001 44.6401 9.81001V18.59C44.6401 21.15 44.6401 23.06 44.6401 23.06L44.6601 26.19C44.6601 29.63 47.1801 31.58 49.1601 31.58L50.1001 31.59V31.61C50.1001 31.61 50.5001 31.61 51.2201 31.61C51.7201 31.61 52.3901 31.61 53.2001 31.61C54.6701 31.61 56.6201 31.61 58.9301 31.61C64.3401 31.61 65.0601 25.41 65.0601 25.41H53.2001C51.7601 25.3 51.5301 24.1 51.4901 23.48Z'
        fill='white'
      />
      <path
        d='M137.39 0C128.72 0 121.63 6.8 121.18 15.36C124.05 15.8 126.33 16.37 127.94 16.86C127.93 16.72 127.92 16.58 127.92 16.44C127.92 11.21 132.16 6.96 137.4 6.96C142.64 6.96 146.88 11.2 146.88 16.44C146.88 21.68 142.64 25.92 137.4 25.92C134.79 25.92 132.42 24.86 130.71 23.15C130.28 24.52 129.7 26.09 128.92 27.69C128.62 28.3 128.31 28.89 127.98 29.46C130.64 31.36 133.89 32.47 137.4 32.47C146.37 32.47 153.64 25.2 153.64 16.23C153.62 7.27 146.36 0 137.39 0Z'
        fill='white'
      />
      <path
        d='M111.7 17.64C111.07 17.64 110.42 17.65 109.78 17.67C110.01 18.24 110.45 19.03 111.25 19.95C112.35 21.22 113.78 22.35 115.4 23.26C113.75 24.78 111.55 25.71 109.13 25.71C104.01 25.71 99.6301 21.33 99.6301 16.21C99.6301 11.09 103.85 7.01 108.97 7.01C110.1 7.01 111.17 7.21 112.17 7.58C112.36 7.65 112.56 7.73 112.74 7.82C115.51 8.73 119.77 7.14 120.54 4.7C117.61 1.79 113.58 0 109.13 0C100.16 0 92.8901 7.27 92.8901 16.24C92.8901 25.21 100.16 32.48 109.13 32.48C111.66 32.48 114.5 32.01 117.08 30.67C116.44 33.25 116.16 35.6 116.22 37.05C124.9 32.51 127.66 23.52 128.41 20.16C126.08 19.35 120.13 17.64 111.7 17.64Z'
        fill='white'
      />
    </svg>
  );
};
export default LogoIcon;
