import React from 'react';
import {
  Box,
  Card,
  Typography,
  Container,
  Divider,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`,
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
`,
);

const ButtonSearch = styled(Button)(
  ({ theme }) => `
    margin-right: -${theme.spacing(1)};
`,
);

function Page404() {
  return (
    <>
      <Helmet>
        <title>Статус - 404</title>
      </Helmet>
      <MainContent>
        <Container maxWidth='md'>
          <Box textAlign='center'>
            <img alt='404' height={180} src='/static/images/404.svg' />
            <Typography variant='h2' sx={{ my: 2 }}>
              Страница, которую вы искали, не существует.
            </Typography>
          </Box>
          <Container maxWidth='sm'>
            <Card sx={{ textAlign: 'center', mt: 3, p: 4 }}>
              <FormControl variant='outlined' fullWidth>
                <OutlinedInputWrapper
                  type='text'
                  placeholder='Поиск здесь...'
                  endAdornment={
                    <InputAdornment position='end'>
                      <ButtonSearch variant='contained' size='small'>
                        Найти
                      </ButtonSearch>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position='start'>
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Divider sx={{ my: 4 }}>ИЛИ</Divider>
              <Button component={Link} to='/' variant='outlined'>
                Пройтись до дома
              </Button>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
}

export default Page404;
